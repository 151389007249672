<template>
  <div id="add-event">
    <h1>{{ editMode ? $t("EVENT.EDIT_EVENT") : $t("EVENT.ADD_EVENT") }}</h1>
    <form class="mt-5" v-if="!editMode || (editMode && loadedEvent)">
      <div class="row">
        <div class="col-4">
          <div class="form-group">
            <label for="event-name">{{ $t("EVENT.EVENT_NAME") }}</label>
            <input
              type="text"
              class="form-control"
              id="event-name"
              :placeholder="$t('EVENT.EVENT_NAME')"
              v-model="event.name"
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="event-location">{{ $t("EVENT.EVENT_LOCATION") }}</label>
            <input
              type="text"
              class="form-control"
              id="event-location"
              :placeholder="$t('EVENT.EVENT_LOCATION')"
              v-model="event.location"
            />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="event-date">{{ $t("EVENT.EVENT_DATE") }}</label>
            <input
              type="date"
              class="form-control"
              v-model="event.date"
              id="event-date"
              :min="minDate"
            />
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-6">
          <label for="event-date">{{ $t("EVENT.EVENT_TEXT") }}</label>
          <input
            type="text"
            class="form-control"
            v-model="event.text"
            id="event-text"
          />
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-2">
          <v-switch
            v-model="event.hasForm"
            :label="$t('EVENT.HAS_FORM')"
          ></v-switch>
        </div>
      </div>

      <div class="row mt-0">
        <div class="col-2">
          <v-switch
            v-model="event.hasQuestionnaire"
            :label="$t('EVENT.HAS_QUESTIONNAIRE')"
          ></v-switch>
        </div>
      </div>

      <div class="row mt-0">
        <div class="col-2">
          <v-switch
            v-model="event.hasPhoto"
            :label="$t('EVENT.HAS_PHOTO')"
          ></v-switch>
        </div>
      </div>

      <div class="row mt-0 align-items-center">
        <div class="col-2">
          <v-switch
            v-model="event.hasVoucher"
            :label="$t('EVENT.HAS_VOUCHER')"
          ></v-switch>
        </div>
        <div class="col-4" v-if="event.hasVoucher">
          <div class="form-group  mt-4">
            <input
              type="text"
              class="form-control"
              id="voucher-text"
              placeholder="Voucher Text"
              v-model="event.voucherText"
            />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button
          :disabled="isDisabled"
          @click.prevent="onSubmit"
          class="btn btn-primary"
        >
          {{ $t("COMMON.SUBMIT") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  created() {
    if (this.$route.name === "Edit Event") {
      this.editMode = true;
      this.getEvent({ id: this.$route.params.id });
    } else {
      this.editMode = false;
      this.resetFields();
    }
  },
  watch: {
    loadedEvent() {
      if (this.loadedEvent) {
        this.event.name = this.loadedEvent.eventName;
        this.event.location = this.loadedEvent.location;
        this.event.date = this.loadedEvent.eventDate;
        this.event.hasForm = !this.loadedEvent.hasPrintForm ? false : true;
        this.event.hasPhoto = !this.loadedEvent.hasPhoto ? false : true;
        this.event.hasQuestionnaire = !this.loadedEvent.hasQuestionnaire
          ? false
          : true;
        this.event.hasVoucher = !this.loadedEvent.hasVoucher ? false : true;
        this.event.voucherText = this.loadedEvent.voucherText;
        this.event.text = this.loadedEvent.eventText;
      }
    },
    $route(to, from) {
      if (to.name === "Add Event") {
        this.resetFields();
      }
    },
  },
  data() {
    return {
      event: {
        name: null,
        location: null,
        date: null,
        hasPhoto: false,
        hasForm: false,
        hasQuestionnaire: false,
        hasVoucher: false,
        voucherText: null,
        text: null,
      },
      loading: false,
      editMode: false,
    };
  },
  methods: {
    ...mapActions({
      saveEvent: "saveEvent",
      getEvent: "getEvent",
      updateEvent: "updateEvent",
    }),
    onSubmit() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const event = {
        eventName: this.event.name,
        eventDate: this.event.date,
        location: this.event.location,
        eventText: this.event.text,
        voucherCode: 1,
        hasVoucher: this.event.hasVoucher,
        hasPrintForm: this.event.hasForm,
        hasPhoto: this.event.hasPhoto,
        hasQuestionnaire: this.event.hasQuestionnaire,
        voucherText: this.event.hasVoucher ? this.event.voucherText : null,
      };

      if (this.editMode) {
        this.updateEvent({
          vm: this,
          id: this.$route.params.id,
          data: {
            event,
          },
        });
      } else {
        event.count = 0;
        this.saveEvent({
          vm: this,
          data: {
            event,
          },
        });
      }
    },
    resetFields() {
      this.event = {
        name: null,
        location: null,
        date: null,
        hasVoucher: false,
        hasForm: false,
        hasPhoto: false,
        hasQuestionnaire: false,
        vouherText: null,
        text: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      loadedEvent: "event",
    }),
    minDate() {
      var dtToday = new Date();

      var month = dtToday.getMonth() + 1;
      var day = dtToday.getDate();
      var year = dtToday.getFullYear();

      if (month < 10) month = "0" + month.toString();
      if (day < 10) day = "0" + day.toString();

      return year + "-" + month + "-" + day;
    },
    isDisabled() {
      if (
        this.event.name === "" ||
        this.event.name === null ||
        this.event.location === "" ||
        this.event.location === null ||
        this.event.date === null ||
        this.event.date < this.minDate ||
        this.event.text === "" ||
        this.event.text === null ||
        (this.event.hasVoucher && this.event.voucherText === "") ||
        (this.event.hasVoucher && this.event.voucherText === null)
      ) {
        return true;
      } else return false;
    },
  },
};
</script>

<style lang="scss" scoped>
#add-event {
  background: #fff;
  padding: 20px;

  h1 {
    font-size: 20px;
    color: #3c4eba;
    font-weight: 500;
  }
}
</style>
